<template>
  <!--
    The view for the StartFinish, CameraManagement, VideoDirector and VideoPublisher-components.
    Used to display and edit information about the installation.
  -->
  <div class="installationDetail_Tracker">
    <LoadingPlaceholder v-if="loading || envLoading" />
    
    <template v-else>
      <div
        v-if="!environmentInformation.showDualslalom || environmentInformation.showEventMovie"
        class="row"
      >
        <div
          class="col-12 col-md-6"
        >
          <div
            v-if="environmentInformation.showStartFinish"
            class="card"
          >
            <StartFinish
              ref="startFinishComponent"
              :installation-id="installationId"
              @showSidebar="openOperationModeSidebar"
            />
          </div>
          <div
            v-if="environmentInformation.showVideoPublisher"
          >
            <div class="card">
              <VideoPublisherStatus
                ref="videoPublisherStatus"
                :installation-id="installationId"
              />
            </div>
          </div> 
          <div
            v-if="environmentInformation.showCameraManagement"
            class="card"
          >
            <CameraManagement :installation-id="installationId" />
          </div>
        </div>
        <div
          class="col-12 col-md-6"
        >
          <div
            v-if="environmentInformation.showVideoDirector"
          >
            <div class="card">
              <VideoDirector :installation-id="installationId" />
            </div>
          </div>
        </div>
        <Portlet
          v-if="!authenticationHasRole('zerolevel') && !authenticationHasRole('firstlevel') && !authenticationHasRole('firstlevel_connector') && envLoading == true"
          :title="$t('installationDetailDevices.localDeviceManagement')"
          icon="hdd"
          class="col-12"
        >
          <LoadingPlaceholder />
        </Portlet>
        <template v-else-if="!authenticationHasRole('zerolevel') && !authenticationHasRole('firstlevel') && !authenticationHasRole('firstlevel_connector')">
          <template v-if="environmentInformation != null">
            <div
              v-if="environmentInformation.showLocalDeviceManagement == true"
              class="card col-12"
            >
              <LocalDeviceManagement
                ref="localDeviceManagement"
                :installation-id="installationId"
              />
            </div>
          </template>
        </template>
      </div>

      <!-- DualSlalom -->
      <div
        v-else
        class="row"
      >
        <div
          class="col-12 col-md-6"
        >
          <h4 class="p-0 m-0">
            Left Lane (LaneNumber: 2)
          </h4>
          <div
            v-if="environmentInformation.showStartFinish"
            class="card mt-2"
          >
            <StartFinish
              ref="startFinishComponent"
              lane-number="2"
              :installation-id="installationId"
              :ski-school-mode="showSkischoolMode"
              :uuid="smStartUuid"
              @showSidebar="openOperationModeSidebar"
            />
          </div>
          <div
            v-if="environmentInformation.showVideoDirector"
          >
            <div class="card">
              <VideoDirector
                :installation-id="installationId"
                lane-number="2"
              />
            </div>
          </div>
          <div
            v-if="environmentInformation.showVideoPublisher"
          >
            <div class="card">
              <VideoPublisherStatus
                ref="videoPublisherStatus"
                :installation-id="installationId"
                lane-number="2"
              />
            </div>
          </div> 
          <div
            v-if="environmentInformation.showCameraManagement"
            class="card"
          >
            <CameraManagement
              :installation-id="installationId"
              lane-number="2"
            />
          </div>
          <Portlet
            v-if="!authenticationHasRole('zerolevel') && !authenticationHasRole('firstlevel') && !authenticationHasRole('firstlevel_connector') && envLoading == true"
            :title="$t('installationDetailDevices.localDeviceManagement')"
            icon="hdd"
          >
            <LoadingPlaceholder />
          </Portlet>
          <template v-else-if="!authenticationHasRole('zerolevel') && !authenticationHasRole('firstlevel') && !authenticationHasRole('firstlevel_connector')">
            <template v-if="environmentInformation != null">
              <div
                v-if="environmentInformation.showLocalDeviceManagement == true"
                class="card"
              >
                <LocalDeviceManagement
                  ref="localDeviceManagement"
                  :installation-id="installationId"
                  lane-number="2"
                />
              </div>
            </template>
          </template>
        </div>
        <div
          class="col-12 col-md-6"
        >
          <h4 class="p-0 m-0">
            Right Lane (LaneNumber: 1)
          </h4>
          <div
            v-if="environmentInformation.showStartFinish"
            class="card mt-2"
          >
            <StartFinish
              ref="startFinishComponent"
              lane-number="1"
              :installation-id="installationId"
              :ski-school-mode="showSkischoolMode"
              :uuid="smStartUuid"
              @showSidebar="openOperationModeSidebar"
            />
          </div>
          <div
            v-if="environmentInformation.showVideoDirector"
          >
            <div class="card">
              <VideoDirector
                :installation-id="installationId"
                lane-number="1"
              />
            </div>
          </div>
          <div
            v-if="environmentInformation.showVideoPublisher"
          >
            <div class="card">
              <VideoPublisherStatus
                ref="videoPublisherStatus"
                :installation-id="installationId"
                lane-number="1"
              />
            </div>
          </div> 
          <div
            v-if="environmentInformation.showCameraManagement"
            class="card"
          >
            <CameraManagement
              :installation-id="installationId"
              lane-number="1"
            />
          </div>
          <Portlet
            v-if="!authenticationHasRole('zerolevel') && !authenticationHasRole('firstlevel') && !authenticationHasRole('firstlevel_connector') && envLoading == true"
            :title="$t('installationDetailDevices.localDeviceManagement')"
            icon="hdd"
          >
            <LoadingPlaceholder />
          </Portlet>
          <template v-else-if="!authenticationHasRole('zerolevel') && !authenticationHasRole('firstlevel') && !authenticationHasRole('firstlevel_connector')">
            <template v-if="environmentInformation != null">
              <div
                v-if="environmentInformation.showLocalDeviceManagement == true"
                class="card"
              >
                <LocalDeviceManagement
                  ref="localDeviceManagement"
                  :installation-id="installationId"
                  lane-number="1"
                />
              </div>
            </template>
          </template>
        </div>
      </div>

      <div
        v-if="authenticationHasRole('admin') || authenticationHasRole('secondlevel')"
        class="card"
      >
        <ConfigurationHistory :installation-id="installationId" />
      </div>
    </template>
    <Sidebar
      v-if="showSidebar"
      :show-sidebar="showSidebar"
      @close="close"
    >
      <OperationModeEdit
        :operation-mode="operationMode"
        :installation-id="installationId"
        :lane-number="currentLaneNumber"
        @close="close"
      />
    </Sidebar>
  </div>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: "InstallationDetailTrackerV4",
  components: {
    ConfigurationHistory: () => import('@/components/Config/ConfigurationHistory.vue'),
    VideoDirector: () => import('@/components/VideoDirector/VideoDirector.vue'),
    StartFinish: () => import('@/components/StartFinish/StartFinish.vue'),
    VideoPublisherStatus: () => import('@/components/VideoPublisher/VideoPublisherStatus.vue'),
    CameraManagement: () => import('@/components/CameraManagement/CameraManagement.vue'),
    Sidebar: () => import('@/components/Base/Sidebar.vue'),
    OperationModeEdit: () => import('@/components/StartFinish/OperationModeEdit.vue'),
    LocalDeviceManagement: () => import('@/components/Device/LocalDeviceManagement.vue')
  },
  mixins: [
    authenticationMixin,
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      installationObject: null,
      loading: true,
      environmentInformation: null,
      envLoading: true,
      showSidebar: false,
      operationMode: null,
      currentLaneNumber: null,
      showSkischoolMode: false,
      smStartUuid: null
    }
  },
  metaInfo () {
    return {
      title: 'Tracker V4'
    }
  },
  created () {
    this.getInstallation();
    this.getEnvironmentInfo();
  },
  methods: {
    openOperationModeSidebar (operationMode, laneNumber) {
      this.operationMode = operationMode;
      this.currentLaneNumber = laneNumber;
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
    getInstallation () {
      this.loading = true;
      this.axios.get('/Installation/Get?id=' + this.installationId)
        .then((response) => {
          this.installationObject = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getEnvironmentInfo () {
      this.axios.get(`/Installation/GetEnvironmentInformation/${ this.installationId }`)
        .then((result) => {
          if (result == null) {
            return;
          }
          if (result.data == null) {
            return;
          }
          this.environmentInformation = result.data;
          this.showSkischoolMode = result.data.showSkischoolMode;
          this.smStartUuid = result.data.smStartUuid;
        })
        .finally(() => {
          this.envLoading = false;
        });
    }
  }
}
</script>